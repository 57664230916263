import BeraIcon from "@/assets/Images/bera-coin.png"
import RocketButton from '@/assets/Images/rocket-button.png'
import DAppFocusedSvg from '@/assets/svg/DAppsForcusedSvg.svg'
import DappsSvg from '@/assets/svg/DAppsSvg.svg'
import BearFocusedSvg from '@/assets/svg/bear-focused.svg'
import BearSvg from '@/assets/svg/bear.svg'
import HomeFocusedSvg from '@/assets/svg/home-focused.svg'
import HomeSvg from '@/assets/svg/home.svg'
import Checked from "@/assets/svg/ic-checked.svg"
import InviteFocusedSvg from '@/assets/svg/invite-focused.svg'
import InviteSvg from '@/assets/svg/invite.svg'
import TaskFocusedSvg from '@/assets/svg/task-focused.svg'
import TaskSvg from '@/assets/svg/task.svg'
import { AccountHeader, Container } from '@/components/Layout'
import { DailyRewardModal } from '@/components/Modal/DailyRewardModal'
import { useConnectSocket } from '@/libs/hooks'
import { type MemeToken, Tabs } from '@/libs/types'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import { checkIn, getCheckInInfo } from '@/libs/utils/wallet/action'
import { TabGame } from '@/pages/Game'
import { bottomTabStore } from '@/store/BottomTabStore'
import { useRemainingFuel } from '@/store/Game'
import { walletStore } from "@/store/WalletStore"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Badge, Tabbar } from '@telegram-apps/telegram-ui'
import { useSelector } from '@xstate/store/react'
import { format } from 'date-fns'
import type React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { DetailMeme } from '../components/TabDetailMeme/DetailMeme'
import { CreateMeme } from '../components/TabMeme/CreateMeme'
import { TabDApps } from './TabDApps'
import { TabInvite } from './TabInvite'
import { TabLeaderBoad } from './TabLeaderBoad'
import { TabMeme } from './TabMeme'
import { TabTask } from './TabTask'
import { TabWallet } from './TabWallet'

type checkInData = {
  continuous_days: number,
  last_check_in_at: string
}

const eventNames: Record<Tabs, string> = {
  wallet: 'HomeTabClick',
  leaderboard: 'LeaderBoardTabClick',
  game: 'GameTabClick',
  invite: 'InviteTabClick',
  tasks: 'TasksTabClick',
  dapps: 'DAppsTabClick',
  meme: 'MemeTabClick',
  createMeme: 'CreateMemeTabClick',
  detailMeme: 'DetailMemeTabClick',
}

const tabs = [
  {
    id: Tabs.Wallet,
    text: 'Home',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <img
        src={isFocused ? HomeFocusedSvg : HomeSvg}
        alt="game"
        className="w-6 h-6"
      />
    ),
  },

  {
    id: Tabs.DApps,
    text: 'DApps',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <img
        src={isFocused ? DAppFocusedSvg : DappsSvg}
        alt="game"
        className="w-6 h-6"
      />
    ),
  },
  {
    id: Tabs.Meme,
    text: 'Meme',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <img
        src={isFocused ? InviteFocusedSvg : InviteSvg}
        alt="game"
        className="w-6 h-6"
      />
    ),
  },
  {
    id: Tabs.Game,
    text: 'Game',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <img
        src={isFocused ? BearFocusedSvg : BearSvg}
        alt="game"
        className="w-6 h-6"
      />
    ),
  },
  {
    id: Tabs.Tasks,
    text: 'Tasks',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <img
        src={isFocused ? TaskFocusedSvg : TaskSvg}
        alt="game"
        className="w-6 h-6"
      />
    ),
  },
]

const BottomTab: React.FC = () => {
  const cs = useConnectSocket()
  const {activeAccount} = useSelector(
    walletStore,
    (state) => state.context,
  )

  const {
    data: checkInInfo,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['daily check in info'],
    queryFn: () => getCheckInInfo(),
    refetchOnWindowFocus: true,
  })

  const { currentTab } = useSelector(bottomTabStore, (state) => state.context)
  const currentFuel = useRemainingFuel()

  const [tokenSelect, setTokenSelect] = useState<MemeToken>()
  const [scrollElementMeme, setScrollElementMeme] = useState<"trading"|"chart"|null>(null)
  const [open, setOpen] = useState<boolean>(true)

  const renderContent = () => {
    switch (currentTab) {
      case Tabs.Wallet:
        return (
          <TabWallet />
        )
      case Tabs.Game:
        return <TabGame {...cs} handleChangeTab={handleChangeTab} />
      case Tabs.Tasks:
        return <TabTask />
      case Tabs.Leaderboard:
        return <TabLeaderBoad />
      case Tabs.Invite:
        return <TabInvite />
      case Tabs.DApps:
        return <TabDApps />
      case Tabs.Meme:
        return <TabMeme setTokenSelect={setTokenSelect} setScrollElementMeme={setScrollElementMeme} />
      case Tabs.CreateMeme:
        return <CreateMeme />
      case Tabs.DetailMeme:
        return <DetailMeme tokenSelect={tokenSelect as MemeToken} scrollElementMeme={scrollElementMeme} />
      default:
        return (
          <TabWallet />
        )
    }
  }

  const handleChangeTab = (tab: Tabs) => {
    handleReactGASendEvent(eventNames[tab])

    bottomTabStore.send({
      type: 'setCurrentTab',
      tab,
    })
  }

  const checked = useMemo(() => {
    let result = true
    if(!isLoading && activeAccount?.address) {
      if(checkInInfo?.last_check_in_at) {
        const lastTimeCheckIn = format(new Date(checkInInfo?.last_check_in_at), 'dd-MM-yy')
        const currentDate = format(new Date(), 'dd-MM-yy')
        if(lastTimeCheckIn !== currentDate) {
          result = false
        }
      } else if(checkInInfo?.continuous_days === 0) {
        result = false
      }
    }
    return result
  }, [checkInInfo, isLoading, activeAccount])

  return (
    <Container className="px-0" center={<AccountHeader />}>
      <div className="flex-grow">{renderContent()}</div>
      {DailyReward(open, setOpen, checkInInfo, checked, refetch)}

      <Tabbar className="fixed bottom-0 pb-5 w-full bg-blue rounded-t-2xl z-50">
        {tabs.map(({ id, text, Icon }) =>
          id !== Tabs.Meme ? (
            <Tabbar.Item
              className={`p-2 gap-1 ${id === currentTab ? 'text-yellow' : 'text-white'}`}
              key={id}
              text={text}
              selected={id === currentTab}
              onClick={() => handleChangeTab(id)}
            >
              {id === Tabs.Game && currentFuel > 0 && (
                <Badge
                  className="absolute top-1 right-1 bg-[#FFDE5D] text-[#003F60] text-[10px]"
                  type={'number'}
                >
                  {currentFuel}
                </Badge>
              )}
              <Icon isFocused={id === currentTab} />
            </Tabbar.Item>
          ) : (
            <div
              key={id}
              className="relative w-[80px] h-[80px]"
              onClick={() => handleChangeTab(id)}
              onKeyDown={() => {}}
            >
              <img
                src={RocketButton}
                alt="rocket button"
                className="w-[80px] h-[80px] absolute top-[-15px] left-0"
              />
            </div>
          ),
        )}
      </Tabbar>
    </Container>
  )
}

const DailyReward = (open: boolean, setOpen:(value: boolean) => void, checkInInfo: checkInData, checked: boolean, refetch:() => void) => {
  const [rankDay, setRankDay] = useState<number>(1)
  const queryClient = useQueryClient();

  useEffect(() => {
    if(checkInInfo) {
      if(checkInInfo?.continuous_days) {
        if(checkInInfo?.last_check_in_at) {
          const lastTimeCheckIn = format(new Date(checkInInfo?.last_check_in_at), 'dd-MM-yy')
          const now = new Date()
          const subDay = now.getTime() - (24*60*60*1000)
          if(lastTimeCheckIn === format(subDay, 'dd-MM-yy')) {
            setRankDay(checkInInfo.continuous_days + 1)
          }
        } else {
          setRankDay(checkInInfo.continuous_days + 1)
        }
      }
    }
  }, [checkInInfo])

  const arrayLever = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]

  const completeMutation = useMutation({
    mutationFn: checkIn,
    onSuccess: () => {
      toast.success('Check in success')
      queryClient.invalidateQueries({
        queryKey:["my-point"]
      })
    },
    onError: (error) => {
      toast.error(`Unable to check in: ${error.message}`)
    },
  })
  const handleCheckIn = async () => {
    completeMutation.mutate()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    refetch()
    setOpen(false)
  }
  return !checked && (
    <DailyRewardModal
      open={open}
      onClose={() => setOpen(false)}
      heightPercent={0.67}
      checkIn={handleCheckIn}
      checked={checked}
    >
      <div className="flex flex-wrap gap-[5px] items-start justify-center">
        {arrayLever.map((element) => {
          const classContain = 'bg-[#1B5A88] h-[80px] w-[18%] rounded-[12px] border-solid flex items-center justify-around flex-col relative'
          const borderColor = element === (checked ? rankDay - 1 : rankDay) ? `${classContain} border-[#FFDE5E] border-[2px]` : `${classContain} border-[#2B7AB4] border-[1.5px]`
          return (
            <div key={element} className={borderColor}>
              {rankDay > element && <div className='absolute w-[100%] h-[100%] bg-[#00000080] rounded-[12px]' />}
              <p className='text-[10px] text-[#AFDDFF] font-[400] leading-[12px] font-[DM Sans]'>Day {element}</p>
              <img src={rankDay > element ? Checked : BeraIcon} alt='bera icon' className='w-[20px] h-[20px]' />
              <p className='text-[12px] text-[#FFDE5E] font-bold leading-[16px] font-[DM Sans]'>{(100 * element).toLocaleString('en-US')}</p>
            </div>
          )
        })}
      </div>
    </DailyRewardModal>
  )
}

export { BottomTab }
