
import type { Nft } from '@/libs/types'

export type NFTCardProps = { nft: Nft }

const NFTCard: React.FC<NFTCardProps> = ({ nft }) => {
  const { name, image_url } = nft

  return (
    <div key={nft.id} className={"rounded-[18px] p-[1.5px] w-[calc(50%-10px)] bg-[#52A7E7] relative"}>
      <img src={image_url} alt={`NFT ${name}`} className='w-full h-[auto] rounded-[18px]' />
      <div className='p-[10px]'>
      <p className="text-[#000000] text-[14px] font-[700]">{name}</p>
      <p className="text-[#000000CC] text-[12px] font-[400]">Bera Chain</p>
      </div>
    </div>
  )
}

export { NFTCard }
