import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import ArrowDownSvg from '@/assets/svg/arrow-down.svg'
import CheckedSvg from '@/assets/svg/ic-checked.svg'
import CopySvg from '@/assets/svg/ic-copy-wallet.svg'
import { Header } from '@/components/Layout'
import { useWalletAddress } from '@/libs/hooks'
import type { WalletAccount } from '@/libs/types'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { IconMenu2 } from '@tabler/icons-react'
import { useQueryClient } from '@tanstack/react-query'
import { IconButton } from '@telegram-apps/telegram-ui'
import { useSelector } from '@xstate/store/react'
import { useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { isAddressEqual } from 'viem'
import { BottomSheetModal } from '../Modal/BottomSheetModal'

const AccountHeader: React.FC = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const activeWalletAddress = useWalletAddress()
  const { accounts, activeAccount } = useSelector(
    walletStore,
    (state) => state.context,
  )
  const queryClient = useQueryClient()

  const activeWallet = (account: WalletAccount) => {
    walletStore.send({
      type: 'activeWallet',
      account,
    })
    queryClient.invalidateQueries({
      queryKey: ['tokens'],
    })

    setOpen(false)
  }

  const copyAddress = () => {
    navigator.clipboard.writeText(activeWalletAddress as string)
    toast.success('Copied successfully')
  }

  return (
    <>
      <Header
        left={
          <IconButton
            mode="plain"
            onClick={() => {
              handleReactGASendEvent('BurgerMenuClick')
              navigate('/account-setting')
            }}
          >
            <IconMenu2 className="text-white" />
          </IconButton>
        }
        center={
          <div className="flex items-center py-1 px-4 border border-white rounded-2xl bg-rgba-800">
            <Button
              className="flex items-center"
              onPress={() => {
                handleReactGASendEvent('WalletDetailClick')
                setOpen(true)
              }}
            >
              <Text className="text-rgba font-semibold truncate w-[120px]">
                {activeAccount?.name
                  ? activeAccount.name
                  : formatWalletAddress(activeWalletAddress as string, 7)}
              </Text>
              <img
                src={ArrowDownSvg}
                className="w-4 h-4 ml-1"
                alt="arrow-down"
              />
            </Button>
            <Button className="border-l-2 ml-3" onPress={copyAddress}>
              <img src={CopySvg} className="w-4 h-4 ml-3" alt="copy" />
            </Button>
          </div>
        }
        // right={
        //   <IconButton mode="plain">
        //     <img src={QrSvg} alt="avatar" className="w-6 h-6" />
        //   </IconButton>
        // }
      />
      <BottomSheetModal
        open={open}
        title="Switch wallet"
        onClose={() => setOpen(false)}
        heightPercent={0.52}
      >
        <div className="flex flex-col px-4 py-5 gap-3">
          {accounts.map((account) => {
            const isActiveWallet = isAddressEqual(
              activeWalletAddress as `0x${string}`,
              account.address,
            )
            return (
              <Button
                key={account.address}
                className={`flex items-center justify-between px-3 py-4 border-[1.5px] rounded-xl ${
                  isActiveWallet ? 'border-yellow' : 'border-white'
                }
             )}`}
                onPress={() => activeWallet(account)}
              >
                <div className="flex items-center">
                  <img
                    src={WalletDefaultAvt}
                    alt="wallet"
                    className="w-6 h-6"
                  />
                  <div className="flex flex-col ml-3">
                    <Text className="text-[14px] font-semibold text-left">
                      {account.name}
                    </Text>
                    <Text className="text-[12px] font-semibold opacity-64">
                      {formatWalletAddress(account.address)}
                    </Text>
                  </div>
                </div>
                {isActiveWallet && (
                  <img src={CheckedSvg} alt="checked" className="w-6 h-6" />
                )}
              </Button>
            )
          })}
        </div>
      </BottomSheetModal>
    </>
  )
}

export { AccountHeader }
