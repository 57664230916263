import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { Nft } from '../types'

export const getNfts = async () => {
  const res = await request.get<Nft[]>('/wallet/nft')
  return res.data
}

export const useNfts = () => {
  const { data } = useQuery({
    queryKey: ['get-nfts'],
    queryFn: () => getNfts(),
  })

  return data ?? []
}
