import {
  AccountSettingPage,
  ListAccounts,
  ShowMnemonicPage,
  ShowPrivateKeyPage,
} from '@/pages/AccountSetting'
import { ConfirmMnemonic } from '@/pages/ConfirmMnemonic'
import { ConfirmTransactionPage } from '@/pages/ConfirmTransaction'
import { ConfirmWalletPage } from '@/pages/ConfirmWallet'
import { ImportWalletPage } from '@/pages/ImportWallet'
import { MintNFTPages } from '@/pages/MintNFT/MintNFTPage'
import { NewWalletPage } from '@/pages/NewWallet'
import { OnBoardPage } from '@/pages/OnBoard'
import { QrCode } from '@/pages/Recevie'
import { InputReceiver, ListToken } from '@/pages/SendToken'
import { InputAmount } from '@/pages/SendToken/InputAmount'
import { SwapAndPool } from '@/pages/Swap/SwapAndPool'
import { TokenDetailPage, TransactionDetailPage } from '@/pages/TokenDetail'
import { TransactionSuccessPage } from '@/pages/Transaction'
import { IndexPage } from 'pages/IndexPage/IndexPage'
import type { ComponentType, JSX } from 'react'

interface Route {
  path: string
  Component: ComponentType
  icon?: JSX.Element
}

export const routes: Route[] = [
  { path: '/', Component: IndexPage },
  { path: '/on-board', Component: OnBoardPage },
  { path: '/import-wallet', Component: ImportWalletPage },
  { path: '/new-wallet', Component: NewWalletPage },
  { path: '/confirm-wallet', Component: ConfirmWalletPage },
  { path: '/confirm-mnemonic', Component: ConfirmMnemonic },
  { path: '/send-token', Component: InputAmount },
  { path: '/input-receiver', Component: InputReceiver },
  { path: '/confirm-transaction', Component: ConfirmTransactionPage },
  { path: '/transaction-success', Component: TransactionSuccessPage },
  { path: '/receive', Component: QrCode },
  { path: '/qr-code', Component: QrCode },
  { path: '/send-token-list', Component: ListToken },
  { path: '/token-detail', Component: TokenDetailPage },
  { path: '/transaction-detail', Component: TransactionDetailPage },
  { path: '/account-setting', Component: AccountSettingPage },
  { path: '/show-private-key', Component: ShowPrivateKeyPage },
  { path: '/show-mnemonic', Component: ShowMnemonicPage },
  { path: '/list-account', Component: ListAccounts },
  { path: '/mint-nft', Component: MintNFTPages },
  { path: '/swap', Component: SwapAndPool },
]
