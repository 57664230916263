import { Tabs } from '@/libs/types'
import { createStore } from '@xstate/store'

const bottomTabStore = createStore(
  {
    currentTab: Tabs.Wallet,
  },
  {
    setCurrentTab: (_, { tab }: { tab: Tabs }) => ({
      currentTab: tab,
    }),
  },
)

export { bottomTabStore }
