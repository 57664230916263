import { useWalletAddress } from '@/libs/hooks'
import { getTokens } from '@/libs/utils/wallet/action'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { balanceStore } from 'store/balanceStore'

export const useTokens = ({ sendable = true }) => {
  const walletAddress = useWalletAddress()

  const { data, ...res } = useQuery({
    queryKey: ['tokens', walletAddress],
    queryFn: () => getTokens(sendable),
    enabled: !!walletAddress,
  })

  useEffect(() => {
    if (!data) return
    balanceStore.send({
      type: 'setTokens',
      tokens: data,
    })
  }, [data])

  return { tokens: data ?? [], ...res }
}
