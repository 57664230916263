import type {
  AddLiquidityTransactionInfo,
  CreateMemeTransactionInfo,
  SwapTransactionInfo,
} from '@/libs/types'
import { createStore } from '@xstate/store'

const transactionStore = createStore(
  {
    txsSwap: [] as SwapTransactionInfo[],
    txsAddLiquidity: [] as AddLiquidityTransactionInfo[],
    txsBeraPump: [] as string[],
    txsCreateMeme: [] as CreateMemeTransactionInfo[],
    txsMintNFT: [] as string[],
    txsSendBera: [] as string[],
  },
  {
    initData: (
      _,
      {
        txsSwapData,
        txsAddLiquidityData,
        txsBeraPumpData,
        txsCreateMemeData,
        txsMintNFTData,
        txsSendBeraData,
      }: {
        txsSwapData: SwapTransactionInfo[]
        txsAddLiquidityData: AddLiquidityTransactionInfo[]
        txsBeraPumpData: string[]
        txsCreateMemeData: CreateMemeTransactionInfo[]
        txsMintNFTData: string[]
        txsSendBeraData: string[]
      },
    ) => ({
      txsSwap: txsSwapData,
      txsAddLiquidity: txsAddLiquidityData,
      txsBeraPump: txsBeraPumpData,
      txsCreateMeme: txsCreateMemeData,
      txsMintNFT: txsMintNFTData,
      txsSendBera: txsSendBeraData,
    }),

    addSwapTransaction: (
      context,
      { txData }: { txData: SwapTransactionInfo },
    ) => {
      const isExist = context.txsSwap.some((tx) => tx.hash === txData.hash)
      if (isExist) {
        return {
          txsSwap: [...context.txsSwap],
        }
      }
      return {
        txsSwap: [...context.txsSwap, txData],
      }
    },
    addAddLiquidityTransaction: (
      context,
      { txData }: { txData: AddLiquidityTransactionInfo },
    ) => {
      const isExist = context.txsAddLiquidity.some(
        (tx) => tx.hash === txData.hash,
      )
      if (isExist) {
        return {
          txsAddLiquidity: [...context.txsAddLiquidity],
        }
      }

      return {
        txsAddLiquidity: [...context.txsAddLiquidity, txData],
      }
    },
    addBeraPumpTransaction: (context, { txData }: { txData: string }) => {
      const isExist = context.txsBeraPump.some((tx) => tx === txData)
      if (isExist) {
        return {
          txsBeraPump: [...context.txsBeraPump],
        }
      }

      return {
        txsBeraPump: [...context.txsBeraPump, txData],
      }
    },
    addCreateMemeTransaction: (
      context,
      { txData }: { txData: CreateMemeTransactionInfo },
    ) => {
      return {
        txsCreateMeme: [...context.txsCreateMeme, txData],
      }
    },
    addMintNFTTransaction: (context, { txData }: { txData: string }) => {
      return {
        txsMintNFT: [...context.txsMintNFT, txData],
      }
    },
    addSendBeraTransaction: (context, { txData }: { txData: string }) => {
      return {
        txsSendBera: [...context.txsSendBera, txData],
      }
    },
    claimBeraPumpTransaction: () => {
      return {
        txsBeraPump: [],
      }
    },
    clearSwapTransaction: () => {
      return {
        txsSwap: [],
      }
    },
    clearAddLiquidityTransaction: () => {
      return {
        txsAddLiquidity: [],
      }
    },
    clearSendBeraTransaction: () => {
      return {
        txsSendBera: [],
      }
    },
    clearCreateMemeTransaction: () => {
      return {
        txsCreateMeme: [],
      }
    },
    clearMintNFTTransaction: () => {
      return {
        txsMintNFT: [],
      }
    },
    clearTransaction: () => ({
      txsSwap: [],
      txsAddLiquidity: [],
      txsBeraPump: [],
      txsCreateMeme: [],
      txsMintNFT: [],
      txsSendBera: [],
    }),
  },
)

export { transactionStore }
