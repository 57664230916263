import { useQuery } from '@tanstack/react-query'
import { erc1155BalanceOfBatch } from '../utils/wallet/action'

export function useGetHoldNfts(walletAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['getHoldNfts', walletAddress],
    queryFn: () => erc1155BalanceOfBatch(walletAddress),
  })

  return { nfts: data ?? [], ...res }
}
