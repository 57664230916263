import TopImage from '@/assets/Images/daily-reward-top-image.png'
import CloseModalSvg from '@/assets/svg/close-modal.svg'
import { useViewport } from '@telegram-apps/sdk-react'
import { Button as ButtonAria, } from 'react-aria-components'
import { Drawer } from 'vaul'
import { WalletButton } from '../Button'

type Props = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  heightPercent?: number
  checkIn:() => void
  checked: boolean
}

const DailyRewardModal: React.FC<Props> = ({
  open,
  onClose,
  children,
  heightPercent = 0.4,
  checkIn,
  checked
}) => {
  const vp = useViewport()
  const height = vp?.height && vp.height * heightPercent
  return (
    <Drawer.Root
      shouldScaleBackground={false}
      setBackgroundColorOnScale={false}
      noBodyStyles
      open={open}
      onClose={onClose}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-blue/40 backdrop-blur-sm z-50" />
        <Drawer.Content
          className=" flex flex-col rounded-t-[10px] fixed bottom-5 left-3 right-3 z-50"
          style={{
            height: height,
          }}
        >
          <div className="bg-[#003F60] rounded-2xl h-full overflow-hidden">
            <div className="flex flex-col max-w-md mx-auto p-[10px]">
              <div className='relative'>
                <img src={TopImage} alt="daily reward" className='w-full h-[auto]' />
                <ButtonAria onPress={onClose} className='absolute top-[0px] right-[0px]'>
                  <img
                    src={CloseModalSvg}
                    className="w-[28px] h-[28px]"
                    alt="Close"
                  />
                </ButtonAria>
              </div>
              <div className='flex flex-col items-center justify-center mt-[20px] mb-[20px]'>
                <p className='text-[20px] text-[#FFDE5E] font-bold leading-[28px]'>Daily Check-in</p>
                <p className='text-[14px] text-[#FFFFFF] font-[400] leading-[24px]'>Check in continuously to get more $BECA</p>
              </div>
              <div
                className="overflow-y-auto"
                style={{
                  height: `calc(${height}px - 300px)`,
                }}
              >
                {children}
              </div>
              <div className='mt-[20px]'>
              <WalletButton
                onPress={() => checkIn()}
                className="w-[100%] h-[48px]"
                isDisabled={checked}
              >
                <p className='text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]'>Claim</p>
              </WalletButton>
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}

export { DailyRewardModal }
