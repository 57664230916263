import { http, createConfig } from 'wagmi'
import { berachainTestnetbArtio, mainnet } from 'wagmi/chains'

const chain =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? mainnet
    : berachainTestnetbArtio

export const wagmi = createConfig({
  chains: [chain],
  transports: {
    [mainnet.id]: http(),
    [berachainTestnetbArtio.id]: http(),
  },
})
