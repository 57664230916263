import { Tabs } from '@/libs/types'
import { bottomTabStore } from '@/store/BottomTabStore'
import { transactionStore } from '@/store/TransactionStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import {
  bindViewportCSSVars,
  useCloudStorage,
  useMiniApp,
  useSwipeBehavior,
  useViewport,
} from '@telegram-apps/sdk-react'
import { useCallback, useEffect } from 'react'
import ReactGA from 'react-ga4'
import type { Navigator } from 'react-router-dom'
import { TELEGRAM_CLOUD_STORAGE_KEY } from '../types'
import { isUseTelegramApp } from '../utils/constans'
import { AppStorage } from '../utils/storage'

export const useSetupApp = (reactNavigator: Navigator) => {
  const appStorage = useAppStorage()

  useSetupTheme()
  useInit(appStorage, reactNavigator)
  useSyncWalletAccounts(appStorage)
  useSyncTransactionStore(appStorage)
  useTracking()
}

export const useInit = (storage: AppStorage, reactNavigator: Navigator) => {
  const queryClient = useQueryClient()

  const initWalletStoreData = useCallback(async () => {
    const activeAccountData = await storage.get(
      TELEGRAM_CLOUD_STORAGE_KEY.ACTIVE_ACCOUNT,
    )
    const activeAccount = activeAccountData
      ? JSON.parse(activeAccountData)
      : null
    const accountsData = await storage.get(TELEGRAM_CLOUD_STORAGE_KEY.ACCOUNTS)
    const accounts = accountsData ? JSON.parse(accountsData) : []

    walletStore.send({
      type: 'initData',
      activeAccount,
      accounts,
    })

    return activeAccount
  }, [storage])

  const initTransactionStoreData = useCallback(async () => {
    const txsSwapData = await storage.get(TELEGRAM_CLOUD_STORAGE_KEY.TXS_SWAP)
    const txsAddLiquidityData = await storage.get(
      TELEGRAM_CLOUD_STORAGE_KEY.TXS_ADD_LIQUIDITY,
    )
    const txsSwap = txsSwapData ? JSON.parse(txsSwapData) : []
    const txsAddLiquidity = txsAddLiquidityData
      ? JSON.parse(txsAddLiquidityData)
      : []

    const txsBeraPumpData = await storage.get(
      TELEGRAM_CLOUD_STORAGE_KEY.TXS_BERA_PUMP,
    )
    const txsBeraPump = txsBeraPumpData ? JSON.parse(txsBeraPumpData) : []

    const txsCreateMemeData = await storage.get(
      TELEGRAM_CLOUD_STORAGE_KEY.TXS_CREATE_MEME,
    )
    const txsCreateMeme = txsCreateMemeData ? JSON.parse(txsCreateMemeData) : []

    const txsSendBeraData = await storage.get(
      TELEGRAM_CLOUD_STORAGE_KEY.TXS_SEND_BERA,
    )
    const txsSendBera = txsSendBeraData ? JSON.parse(txsSendBeraData) : []

    const txsMintNFTData = await storage.get(
      TELEGRAM_CLOUD_STORAGE_KEY.TXS_MINT_NFT,
    )
    const txsMintNFT = txsMintNFTData ? JSON.parse(txsMintNFTData) : []

    transactionStore.send({
      type: 'initData',
      txsSwapData: txsSwap,
      txsAddLiquidityData: txsAddLiquidity,
      txsBeraPumpData: txsBeraPump,
      txsCreateMemeData: txsCreateMeme,
      txsMintNFTData: txsMintNFT,
      txsSendBeraData: txsSendBera,
    })
  }, [storage])

  useEffect(() => {
    const bootstrap = async () => {
      const activeAccount = await initWalletStoreData()
      await initTransactionStoreData()

      if (activeAccount) {
        // Prefetch the tokens for the active account
        queryClient.prefetchQuery({
          queryKey: ['tokens'],
        })
        queryClient.prefetchQuery({
          queryKey: ['swap-tokens'],
        })

        bottomTabStore.send({
          type: 'setCurrentTab',
          tab: Tabs.Tasks,
        })
        reactNavigator.replace('/')
      } else {
        reactNavigator.replace('/on-board')
      }
    }

    bootstrap()
  }, [
    queryClient,
    reactNavigator,
    initWalletStoreData,
    initTransactionStoreData,
  ])
}

export const useSyncWalletAccounts = (storage: AppStorage) => {
  useEffect(() => {
    const sub = walletStore.subscribe(async (state) => {
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.ACTIVE_ACCOUNT,
        JSON.stringify(state.context.activeAccount),
      )
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.ACCOUNTS,
        JSON.stringify(state.context.accounts),
      )
    })
    return () => sub.unsubscribe()
  }, [storage])
}

export const useSyncTransactionStore = (storage: AppStorage) => {
  useEffect(() => {
    const sub = transactionStore.subscribe(async (state) => {
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.TXS_SWAP,
        JSON.stringify(state.context.txsSwap),
      )
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.TXS_ADD_LIQUIDITY,
        JSON.stringify(state.context.txsAddLiquidity),
      )
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.TXS_BERA_PUMP,
        JSON.stringify(state.context.txsBeraPump),
      )
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.TXS_CREATE_MEME,
        JSON.stringify(state.context.txsCreateMeme),
      )
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.TXS_SEND_BERA,
        JSON.stringify(state.context.txsSendBera),
      )
      await storage.set(
        TELEGRAM_CLOUD_STORAGE_KEY.TXS_MINT_NFT,
        JSON.stringify(state.context.txsMintNFT),
      )
    })
    return () => sub.unsubscribe()
  }, [storage])
}

export const useSetupTheme = () => {
  const miniApp = useMiniApp()
  const swipeBehavior = useSwipeBehavior()
  const viewport = useViewport()

  useEffect(() => {
    function setupTheme() {
      if (miniApp.supports('setBackgroundColor')) {
        miniApp.setBgColor('#060606')
      }
      if (miniApp.supports('setHeaderColor')) {
        miniApp.setHeaderColor('#52A7E7')
      }
      if (import.meta.env.DEV) return
      if (swipeBehavior.supports('disableVerticalSwipe')) {
        swipeBehavior.disableVerticalSwipe()
      }
    }
    // return bindMiniAppCSSVars(miniApp, themeParams)

    setupTheme()
  }, [miniApp, swipeBehavior])

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport)
  }, [viewport])
}

export const useAppStorage = (): AppStorage => {
  const telegramCloudStorage = useCloudStorage()
  const storage = isUseTelegramApp
    ? telegramCloudStorage.supports('get') &&
      telegramCloudStorage.supports('get')
      ? telegramCloudStorage
      : window.localStorage
    : window.localStorage

  return AppStorage.getInstance(storage)
}

export const useTracking = () => {
  useEffect(() => {
    if (!import.meta.env.VITE_GOOGLE_ANALYTICS_ID) return
    ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID)
  }, [])
}
