export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format

export const addCommasToNumber = (value: string | number) => {
  if (value === '' || Number.isNaN(Number(value))) {
    return ''
  }
  const parts = value.toString().split('.')
  const integerPart = Number.parseInt(parts[0]).toLocaleString('en-US')
  // biome-ignore lint/style/useTemplate: <explanation>
  const decimalPart = parts.length > 1 ? '.' + parts[1] : ''
  return integerPart + decimalPart
}

export const formatNumber = (value: number | string, fractionDigits = 2) => {
  const parsedValue = Number.parseFloat(value.toString())

  return parsedValue.toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

function trimEndZeros(numStr: string): string {
  return numStr.replace(/\.?0+$/, '')
}

export function formatPrice(price: number) {
  if (price < 1e-4) {
    const scientificNotation = price.toExponential()
    const [base, exponent] = scientificNotation.split('e-')
    const zeroCount = Number.parseInt(exponent) - 2
    const significantDigits = Math.floor(Number.parseFloat(base) * 10000)
    return `0.0<small>${zeroCount}</small>${trimEndZeros(significantDigits.toString().padStart(1, '0'))}`
  }
  return `${trimEndZeros(price.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }))}`
}
