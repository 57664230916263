import { useEffect, useState } from 'react'
import { Tab, TabList, Tabs as TabsView } from 'react-aria-components'
import { NFTAssets } from '../NFTAssets'
import { TokenAssets } from '../TokenAssets'
import { WalletFeature } from '../WalletFeature'
import '@/styles/aria-tabs.css'
import BearGif from '@/assets/gifs/bear.gif'
import PlaySvg from '@/assets/svg/play-ic.svg'
import PumpSvg from '@/assets/svg/pump.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import BearPumpImg from '@/assets/webps/bera-pump.webp'
import { WalletButton } from '@/components/Button'
import { EmblaCarousel } from '@/components/Carousel'
import { useWalletAddress } from '@/libs/hooks'
import { useBeraBalance } from '@/libs/hooks/useBeraBalance'
import { useMyPoint } from '@/libs/hooks/usePoint'
import { Tabs } from '@/libs/types'
import { BERA_LOGO } from '@/libs/utils/constans'
import {
  handleReactGASend,
  handleReactGASendEvent,
} from '@/libs/utils/tracking'
import { bottomTabStore } from '@/store/BottomTabStore'

enum Assets {
  Token = 'token',
  NFT = 'nft',
}

enum AssetsIndex {
  Token = 0,
  NFT = 1,
}

const TabWallet: React.FC = () => {
  const walletAddress = useWalletAddress()
  const [swipeIndex, setSwipeIndex] = useState(AssetsIndex.Token)
  const point = useMyPoint()
  const { balance: beraBalance } = useBeraBalance(walletAddress as string)

  const slideData = [
    {
      id: 1,
      title: 'Pump On',
      description: 'Earn $BERA by pumping your own token now!',
      imageUrl: BearPumpImg,
      className: 'top-2 right-[0px] w-[200px]',
      balance: beraBalance,
      logo: BERA_LOGO,
      btnComponent: (
        <WalletButton
          className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm"
          color="brown"
          rightIcon={<img src={PumpSvg} alt="pump" className="w-5 h-5" />}
          onPress={() => redirectToPump()}
        >
          Pump Now
        </WalletButton>
      ),
    },
    {
      id: 2,
      title: 'Bear Hunting',
      description: 'Guess BTC price and earn $BECA now!',
      imageUrl: BearGif,
      balance: point,
      logo: BeraCoinImg,
      btnComponent: (
        <WalletButton
          className="absolute bottom-3 right-[14px] text-[#44180B] h-8 rounded-xl text-xs shadow-sm"
          color="white"
          leftIcon={<img src={PlaySvg} alt="play" className="w-4 h-4" />}
          onPress={() => redirectToGame()}
        >
          Play Game
        </WalletButton>
      ),
    },
  ]

  const redirectToGame = () => {
    handleReactGASendEvent('PlayGameButtonClick')

    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Game,
    })
  }
  const redirectToPump = () => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Meme,
    })
  }

  const onTabChange = (key: Assets) => {
    if (key === Assets.Token) {
      handleReactGASendEvent('TokenListClick')
      setSwipeIndex(AssetsIndex.Token)
    } else {
      handleReactGASendEvent('NftListClick')
      setSwipeIndex(AssetsIndex.NFT)
    }
  }

  const tabSelectedKey =
    swipeIndex === AssetsIndex.Token ? Assets.Token : Assets.NFT

  useEffect(() => {
    handleReactGASend('MainScreenHomeView')
  }, [])

  return (
    <div className="flex flex-col overflow-auto max-h-[calc(100vh-170px)]">
      <WalletFeature />
      <EmblaCarousel slides={slideData} />

      <div className="px-4 mt-6">
        <TabsView
          onSelectionChange={(key) => onTabChange(key as Assets)}
          selectedKey={tabSelectedKey}
        >
          <TabList aria-label="Wallet">
            <Tab id={Assets.Token}>Tokens</Tab>
            <Tab id={Assets.NFT}>NFTs</Tab>
          </TabList>

          {tabSelectedKey === Assets.Token && <TokenAssets />}
          {tabSelectedKey === Assets.NFT && <NFTAssets />}

        </TabsView>
      </div>
    </div>
  )
}

export { TabWallet }
